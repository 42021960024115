<template>
  <div class="container">
    <table-page dataName="activities"
                ref="table"
                :search="search"
                :searchFun="searchFun"
                :params="{type:'DRAWAL'}"
                :tabList="tabList"
                :request="fetchActivies">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="$router.push('setExtract')">添加活动</el-button>
      </template>

      <el-table-column prop="id"
                       show-overflow-tooltip
                       label="活动ID">
      </el-table-column>
      <el-table-column prop="act_id"
                       show-overflow-tooltip
                       label="邮政活动id">
      </el-table-column>
      <el-table-column prop="title"
                       show-overflow-tooltip
                       label="活动名称">
      </el-table-column>
      <el-table-column label="活动区域"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{`${scope.row.list_province || ''}${scope.row.list_city || ''}${scope.row.list_district || ''}${scope.row.list_net || ''}`}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="user_total_receive"
                       show-overflow-tooltip
                       label="起止时间">
        <template slot-scope="scope">
          {{scope.row.appointment_started_at}}-{{scope.row.appointment_ended_at}}
        </template>
      </el-table-column>
      <el-table-column label="活动状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 'NOW_PROCEED'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>进行中
          </span>
          <span v-else-if="scope.row.status === 'NOT_PROCEED'">
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>未开始
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>已结束
          </span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="总金额">
        <template slot-scope="scope">
          {{scope.row.total_money/100}}元
        </template>

      </el-table-column>
      <el-table-column prop="join_count"
                       show-overflow-tooltip
                       label="已领取人数">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="已领取金额">
        <template slot-scope="scope">
          {{scope.row.receive_total_money / 100 + "元"}}
        </template>
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作"
                       width="200px">
        <template slot-scope="scope">
          <el-button type="text"
                     class="mr-15"
                     @click="getEntry(scope)">活动入口</el-button>
          <el-dropdown trigger="click"
                       @command="handleCommand($event,scope)">
            <span class="el-dropdown-link">
              <el-button type="text">更多操作</el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="records">查看数据</el-dropdown-item>
              <el-dropdown-item command="edit"
                                v-permission="['ADMIN']">编辑</el-dropdown-item>
              <el-dropdown-item command="del"
                                v-permission="['ADMIN']">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </table-page>
    <MiniCode ref="mini" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import MiniCode from '@/components/MiniCode'
import { fetchActivies, delActivity } from '@/api/market'
export default {
  name: 'Appointments',
  data () {
    return {
      fetchActivies,
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NOT_PROCEED',
        label: '未开始'
      }, {
        value: 'NOW_PROCEED',
        label: '进行中'
      }, {
        value: 'END_PROCEED',
        label: '已结束'
      }],
      search: [{
        type: 'area'
      }, {
        type: 'input',
        name: '活动ID',
        placeholder: '请输入活动ID',
        key: 'activity_id',
        value: ''
      }]
    }
  },
  components: {
    TablePage,
    MiniCode
  },
  methods: {
    getEntry (scope) {
      this.$refs.mini.createMpCode({
        page: 'pages/extract/index',
        scene: 'id=' + scope.row.id
      }, 'pages/extract/index?id=' + scope.row.id)
    },
    searchFun (res) {
      const param = Object.assign({}, res)
      param.list_province = res.province
      param.list_city = res.city
      param.list_district = res.district
      param.list_net = res.net
      delete param.province
      delete param.city
      delete param.district
      delete param.net
      return param
    },
    delActivity (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delActivity({
          activity_ids: [id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    },
    handleCommand (command, scope) {
      switch (command) {
        case 'records':
          this.$router.push({ name: 'ExtractRecords', query: { id: scope.row.id } })
          break
        case 'edit':
          this.$router.push({ name: 'SetExtract', query: { id: scope.row.id } })
          break
        case 'miniCodes':
          this.$router.push({ name: 'ApponitmentRecords', query: { id: scope.row.id } })
          break
        case 'del':
          this.delActivity(scope.row.id)
          break
        default:
          break
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
